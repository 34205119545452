import * as R from "ramda";
import { Component, MouseEvent } from "react";
import { adminAuthApi } from "common/api/authentication/admin";
import { userAuthApi } from "common/api/authentication/user";
import { DISABLED_VENDOR_PORTAL } from "common/api/error";
import { GetCached, getCached } from "common/cache";
import { Culture } from "common/culture/supported-cultures";
import { UserType } from "common/functions/users";
import { dataDog } from "common/monitoring/datadog";
import { ApiCall } from "common/types/api";
import {
  DisplayTenant,
  LoginValue,
  RequestValue,
} from "common/types/authentication";
import { ApiErrorResponse, ApiErrorResponsePayload } from "common/types/error";
import { PasswordPolicy } from "common/types/settings";
import { Tenant } from "common/types/tenants";
import { ApiError } from "common/ui/api-error";
import { trackLogin } from "common/utils/mixpanel";
import { Logo } from "common/widgets/logo";
import { setLocationHref } from "common/utils/window-location";
import { OnSuccess } from "common/vendor-wrappers/auth0/types";
import { LoadingIcon } from "common/widgets/loading-icon";
import {
  DISABLED_PORTAL_PATH,
  MAINTENANCE_PATH,
} from "warning-page/pages/helpers";
import { LoginForm } from "./login-form";
import { MultiFactorAuthForm } from "./multi-factor-auth-form";
import { RequestForm } from "./request-form";
import { ResetForm, ResetFormValue } from "./reset-form";
import { SelectTenant } from "./select-tenant";
import { SuccessMessage } from "./success-message";

interface PropTypes {
  apiCall: ApiCall;
  onSuccess: OnSuccess;
  userName: string;
  language: Culture;
  tenantName?: string;
  token?: string;
  withGoBack?: boolean;
  goBackFn?: () => void;
}

type Step =
  | "login"
  | "loading-policy"
  | "reset"
  | "request"
  | "tenants"
  | "email-sent"
  | "password-changed"
  | "expired"
  | "verify-code";

interface StateType {
  passwordPolicy?: PasswordPolicy;
  loginValue?: LoginValue;
  requestValue?: RequestValue;
  resetValue?: ResetFormValue;
  isSystemUser?: boolean;
  error?: ApiErrorResponse;
  loading?: boolean;
  step?: Step;
  tenant?: Tenant;
  displayTenants?: DisplayTenant[];
}

const createLoginDetailsError = (): ApiErrorResponse => ({
  status: 401,
  data: { message: _("Username and Password are required") },
});

export interface LoginError extends ApiErrorResponsePayload {
  expired: boolean;
  policy: PasswordPolicy;
}
const notExpired: LoginError = {
  expired: false,
  policy: undefined,
  error: undefined,
};

type SetStep = () => void;

export const filterOutInactiveTenants = (tenants: DisplayTenant[]) =>
  tenants.filter((tenant) => tenant.status !== "Inactive");

export class Controller extends Component<PropTypes, StateType> {
  static readonly displayName = "Controller";

  setStep: GetCached<SetStep, Step>;

  constructor(props: PropTypes) {
    super(props);

    const { token, tenantName, userName } = props;
    const password: string = undefined;

    this.state = {
      passwordPolicy: undefined,
      step: token ? "loading-policy" : "login",
      loginValue: { userName: userName, password, tenant: tenantName },
      resetValue: {
        confirmedPassword: undefined,
        newPassword: undefined,
        password,
        userName,
        token,
        tenant: tenantName,
      },
      requestValue: { userName, tenant: tenantName },
      displayTenants: [],
    };

    this.setStep = getCached<SetStep, Step>(
      (step) => () => this.setState({ step, error: undefined }),
    );
  }

  changeStep = (
    step: Step,
    isSystemUser?: boolean,
    displayTenants?: DisplayTenant[],
  ) => {
    this.setState({ step, loading: false, isSystemUser, displayTenants });
  };

  componentDidMount() {
    const { apiCall, tenantName, userName, language } = this.props;
    const { resetValue, step } = this.state;

    dataDog.initialize({
      scope: "login",
      tenant: tenantName,
      language: language,
      user: { name: userName },
    });

    if (step === "loading-policy") {
      this.setState({ loading: true });

      userAuthApi(apiCall)
        .getPolicy(resetValue)
        .then((passwordPolicy) =>
          this.setState({
            loading: false,
            step: "reset",
            passwordPolicy,
          }),
        )
        .catch((error) => this.setState({ error }));
    }
  }

  onRequest = () => {
    const { apiCall } = this.props;
    const { requestValue } = this.state;

    this.setState({ loading: true });

    userAuthApi(apiCall)
      .requestNewPassword(requestValue)
      .then(() => this.changeStep("email-sent"))
      .catch((error) => this.setState({ loading: false, error }));
  };

  onReset = () => {
    const { apiCall } = this.props;
    const { resetValue } = this.state;

    this.setState({ loading: true });

    userAuthApi(apiCall)
      .resetPassword(resetValue)
      .then(() => this.changeStep("password-changed"))
      .catch((error) => {
        this.setState({ loading: false, error });
      });
  };

  onSuccessAuthentication = (
    tenants: DisplayTenant[],
    userTypes: UserType[],
    isSystemUser: boolean,
  ) => {
    const { onSuccess } = this.props;
    const { loginValue } = this.state;

    if (tenants) {
      const filteredTenants = filterOutInactiveTenants(tenants);
      return this.changeStep("tenants", isSystemUser, filteredTenants);
    } else {
      return trackLogin(loginValue.userName, loginValue.tenant, () =>
        onSuccess(userTypes, false),
      );
    }
  };

  setMaintenancePath = (error: any) => {
    const { data = notExpired } = error;
    if (data.tenantStatus === "maintenance") setLocationHref(MAINTENANCE_PATH);
    this.setState({ loading: false, error });
  };

  setDisabledPortalPath = (error: any) => {
    setLocationHref(DISABLED_PORTAL_PATH);
    this.setState({ loading: false, error });
  };

  onFailedAuthentication = (error: any) => {
    const { data = notExpired } = error;
    const { loginValue, resetValue } = this.state;

    if (data.expired) {
      this.setState({
        passwordPolicy: data.policy,
        resetValue: R.mergeRight(resetValue, {
          userName: loginValue.userName,
          password: loginValue.password,
        }),
      });
      this.changeStep("expired");
    } else {
      error?.data?.error === DISABLED_VENDOR_PORTAL
        ? this.setDisabledPortalPath(error)
        : this.setMaintenancePath(error);
    }
  };

  onVerifyCode = (code: string) => {
    const { apiCall } = this.props;
    const { loginValue } = this.state;
    this.setState({ loading: true });

    adminAuthApi(apiCall)
      .verifyCode({ ...loginValue, multiFactorAuthCode: code })
      .then(({ tenants, userTypes, isSystemUser }) =>
        this.onSuccessAuthentication(tenants, userTypes, isSystemUser),
      )
      .catch((error) => {
        this.setState({ loading: false, error });
      });
  };

  onTenantLogin = (tenant: string) => {
    const { tenantName, apiCall, onSuccess } = this.props;
    const { loginValue } = this.state;

    if (!loginValue.userName || !loginValue.password) {
      this.setState({ error: createLoginDetailsError() });
    } else {
      this.setState({ loading: true });

      if (tenantName) {
        userAuthApi(apiCall)
          .login({ ...loginValue, tenant })
          .then(({ tenants, userTypes, isSystemUser }) =>
            this.onSuccessAuthentication(tenants, userTypes, isSystemUser),
          )
          .catch((error) => this.onFailedAuthentication(error));
      } else {
        if (!tenant) {
          adminAuthApi(apiCall)
            .login(loginValue)
            .then(({ tenants, isSystemUser }) => {
              if (!tenants) return this.changeStep("verify-code");

              const filteredTenants = filterOutInactiveTenants(tenants);
              return this.changeStep("tenants", isSystemUser, filteredTenants);
            })
            .catch((error) => this.onFailedAuthentication(error));
        } else {
          adminAuthApi(apiCall)
            .login({ ...loginValue, tenant })
            .then(({ userTypes }) =>
              trackLogin(loginValue.userName, loginValue.tenant, () =>
                onSuccess(userTypes, false),
              ),
            )
            .catch((error) => {
              this.onFailedAuthentication(error);
              this.setState({ error });
            });
        }
      }
    }
  };

  onAdminLogin = () => {
    const { onSuccess } = this.props;
    onSuccess(undefined, true);
  };

  onLoginChange = (loginValue: LoginValue) => {
    this.setState({ loginValue, error: undefined });
  };

  onAuthenticationCodeChange = () => {
    this.setState({ error: undefined });
  };

  onPasswordReset = () => {
    const { loginValue } = this.state;
    this.setState({
      step: "login",
      loginValue: R.mergeRight(loginValue, { password: undefined }),
    });
  };

  onForgotClick = (e: MouseEvent) => {
    e.preventDefault();
    this.setStep("request")();
  };

  onResetValueChange = (resetValue: ResetFormValue) => {
    this.setState({ resetValue, error: undefined });
  };

  onRequestValueChange = (requestValue: RequestValue) => {
    this.setState({ requestValue });
  };

  getStep = () => {
    const { language, withGoBack, goBackFn } = this.props;
    const {
      step,
      loginValue,
      resetValue,
      requestValue,
      isSystemUser,
      loading,
      error,
      passwordPolicy,
      displayTenants,
    } = this.state;

    // data-lang property passed to the components with value to re-render them
    // when the language changes
    switch (step) {
      case "loading-policy":
        return error ? <ApiError error={error} /> : <LoadingIcon />;
      case "login":
        return (
          <LoginForm
            data-lang={language}
            error={error}
            loading={loading}
            onLogin={this.onTenantLogin}
            onForgotClick={this.onForgotClick}
            value={loginValue}
            onChange={this.onLoginChange}
            withGoBack={withGoBack}
            goBackFn={goBackFn}
          />
        );
      case "verify-code":
        return (
          <MultiFactorAuthForm
            onVerifyCode={this.onVerifyCode}
            loading={loading}
            error={error}
            onAuthenticationCodeChange={this.onAuthenticationCodeChange}
          />
        );
      case "expired":
      case "reset":
        return (
          <ResetForm
            data-lang={language}
            passwordPolicy={passwordPolicy}
            expired={step === "expired"}
            onReset={this.onReset}
            loading={loading}
            error={error}
            value={resetValue}
            onChange={this.onResetValueChange}
          />
        );

      case "request":
        return (
          <RequestForm
            data-lang={language}
            onRequest={this.onRequest}
            error={error}
            onCancel={this.setStep("login")}
            value={requestValue}
            onChange={this.onRequestValueChange}
          />
        );

      case "tenants":
        return (
          <SelectTenant
            isSystemUser={isSystemUser}
            onTenant={this.onTenantLogin}
            onAdmin={this.onAdminLogin}
            error={error}
            loading={loading}
            displayTenants={displayTenants}
          />
        );

      case "password-changed":
        return (
          <SuccessMessage
            title={_("Success")}
            message={`${_("Your password has been changed")}.`}
            goToLogin={this.onPasswordReset}
          />
        );

      case "email-sent":
        return (
          <SuccessMessage
            title={_("Success")}
            message={`${_("A link to change your password has been emailed")}.`}
          />
        );
    }
  };

  render() {
    const { tenantName } = this.props;
    return (
      <div className="x-logo-container">
        <Logo tenantName={tenantName} />
        {this.getStep()}
      </div>
    );
  }
}
