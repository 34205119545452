import { useEffect, useState } from "react";
import * as R from "ramda";
import { Culture, SUPPORTED_CULTURES } from "common/culture/supported-cultures";
import { setupLocale } from "common/i18n";
import { ApiCall } from "common/types/api";
import { getBrowserLanguage } from "common/utils/window-location";
import { LanguageSelector } from "common/widgets/language-selector";
import { WithAuth0 } from "./with-auth0";

interface PropTypes {
  apiCall: ApiCall;
  userName: string;
  tenantName?: string;
  token?: string;
}

export const getBrowserCulture = () => {
  const browserLang = getBrowserLanguage(); // Ex.: en-US, pl, es
  const cultureNames = SUPPORTED_CULTURES.map((sc) => sc.name);

  return (
    R.find((sc) => sc === browserLang, cultureNames) || // Ex.: en-US
    R.find((sc) => sc.startsWith(browserLang), cultureNames) || // Ex.: pl
    "en-US"
  );
};

export const LoginControllerWithSso = (props: PropTypes) => {
  const [language, setLanguage] = useState<Culture>(undefined);
  const onLanguageChange = (newLanguage: Culture) => {
    setupLocale(newLanguage).then(() => setLanguage(newLanguage));
  };

  useEffect(() => {
    const culture = getBrowserCulture();
    onLanguageChange(culture);
  }, []);

  const cultures = SUPPORTED_CULTURES.map((sc) => sc.name);
  return (
    <>
      <LanguageSelector
        value={language}
        cultures={cultures}
        onChange={onLanguageChange}
      />
      <WithAuth0 {...props} language={language} />
    </>
  );
};
