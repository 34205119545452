import { getApiCallResolvingData } from "common/api";
import { userAuthApi } from "common/api/authentication/user";
import { apiCallFullWithLoginRedirect } from "common/api/impl";
import { Component } from "common/component";
import { Culture } from "common/culture/supported-cultures";
import { UserType } from "common/functions/users";
import { ApiCall } from "common/types/api";
import { isRequestorUserType, isVendorUserType } from "common/types/users";
import { setLocationHref } from "common/utils/window-location";
import { OnSuccess, Page } from "common/vendor-wrappers/auth0/types";
import { LoadingIcon } from "common/widgets/loading-icon";
import { Controller } from "login/controller";
import { SsoLogin } from "login/sso-login";
import { withAuth0Provider } from "./with-auth0-provider";

const apiCallResolvingData = getApiCallResolvingData(
  apiCallFullWithLoginRedirect,
);

const getUrl = (userTypes: UserType[], isSystemUser: boolean) => {
  if (isRequestorUserType(userTypes)) {
    return "/requestor/";
  }
  if (isVendorUserType(userTypes)) {
    return "/vendor/";
  }

  return isSystemUser ? "/admin/" : "/";
};

const onSuccess: OnSuccess = (userTypes, isSystemUser) => {
  const url = getUrl(userTypes, isSystemUser);

  setLocationHref(url);
};

interface PropTypes {
  apiCall: ApiCall;
  userName: string;
  language: Culture;
  tenantName?: string;
  token?: string;
}

interface StateType {
  page?: Page;
  clientId?: string;
  organizationId?: string;
  userNameToValidate?: string;
  allowPasswordLogin?: boolean;
}

export class WithAuth0 extends Component<PropTypes, StateType> {
  state: StateType = {
    page: undefined,
    clientId: undefined,
    userNameToValidate: undefined,
    allowPasswordLogin: undefined,
  };

  setLoginPage = (page: Page) => this.setState({ page });

  setUserNameToValidate = (userName?: string) =>
    this.setState({ userNameToValidate: userName });

  componentDidMount() {
    const { page } = this.state;
    const { apiCall, tenantName, token } = this.props;

    if (!tenantName || token) {
      this.setLoginPage("x5login");
      return;
    }

    if (page) return;

    userAuthApi(apiCall)
      .getSsoConfiguration(tenantName)
      .then(({ auth0ClientId, organizationId, allowPasswordLogin }) => {
        if (!auth0ClientId) throw new Error("Missing auth0ClientId");

        this.setState({
          page: "auth0login",
          clientId: auth0ClientId,
          organizationId,
          allowPasswordLogin,
        });
      })
      .catch(() => {
        this.setState({
          page: "x5login",
        });
      });
  }

  render() {
    const {
      page,
      userNameToValidate,
      clientId,
      organizationId,
      allowPasswordLogin,
    } = this.state;
    const { tenantName, apiCall, token, userName, language } = this.props;

    switch (page) {
      case "auth0login":
        return withAuth0Provider(
          <SsoLogin
            apiCall={apiCall}
            allowPasswordLogin={allowPasswordLogin}
            tenantName={tenantName}
            preFilledUserName={userNameToValidate}
            onSuccess={onSuccess}
            setPage={this.setLoginPage}
            onUserNameSave={this.setUserNameToValidate}
          />,
          clientId,
          organizationId,
        );
      case "x5login":
        return (
          <Controller
            apiCall={apiCallResolvingData}
            onSuccess={onSuccess}
            tenantName={tenantName}
            language={language}
            token={token}
            userName={userName ?? userNameToValidate}
            withGoBack={!!userNameToValidate}
            goBackFn={() => this.setLoginPage("auth0login")}
          />
        );
      default:
        return <LoadingIcon />;
    }
  }
}
