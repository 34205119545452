import * as React from "react";

interface PropTypes {
  children: React.ReactNode;
}

export const Layout = ({ children }: PropTypes) => {
  return (
    <div className="x-login">
      <header />
      <div className="x-login-container">
        <div className="x-login-box">{children}</div>
      </div>
    </div>
  );
};
